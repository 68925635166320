<template>
	<div>
		<div
			ref="embedContainer"
			class="w-full max-w-full py-2"
		/>
	</div>
</template>

<script setup>
import { onMounted, ref, toRefs, watch } from 'vue'

const embedContainer = ref(null)
const props = defineProps({
	embedHtml: {
		type: String,
		required: true
	}
})
const { embedHtml } = toRefs(props)

const loadGleam = () => {
	if (!embedContainer.value) return
	const gleamElement = embedContainer.value
	gleamElement.innerHTML = props.embedHtml
	Array.from(gleamElement.querySelectorAll('script')).forEach(oldScript => {
		const newScript = document.createElement('script')
		Array.from(oldScript.attributes)
			.forEach(attr => newScript.setAttribute(attr.name, attr.value))
		newScript.appendChild(document.createTextNode(oldScript.innerHTML))
		oldScript.parentNode.replaceChild(newScript, oldScript)
	})
}

onMounted(() => {
	loadGleam()
})

watch(embedHtml, () => {
	loadGleam()
})
</script>
